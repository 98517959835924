exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-details-index-js": () => import("./../../../src/pages/Blogs/Details/index.js" /* webpackChunkName: "component---src-pages-blogs-details-index-js" */),
  "component---src-pages-blogs-index-js": () => import("./../../../src/pages/Blogs/index.js" /* webpackChunkName: "component---src-pages-blogs-index-js" */),
  "component---src-pages-case-study-index-js": () => import("./../../../src/pages/CaseStudy/index.js" /* webpackChunkName: "component---src-pages-case-study-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/News/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-pricings-js": () => import("./../../../src/pages/pricings.js" /* webpackChunkName: "component---src-pages-pricings-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-terms-of-service-index-js": () => import("./../../../src/pages/terms-of-service/index.js" /* webpackChunkName: "component---src-pages-terms-of-service-index-js" */)
}

